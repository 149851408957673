body {
  margin: 0;
  font-family: 'Noto Serif JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gold-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Allura&family=Noto+Sans+JP:wght@100..900&family=Noto+Serif+JP:wght@200..900&family=Shippori+Mincho+B1&display=swap');

.btn-gold {
  @apply block text-center py-[10px] w-[256px] text-gold-dark border border-gold-dark hover:text-gold-light hover:bg-gold-dark hover:scale-110 transition;
}

/* トップページ */

.top-image-slide-ubai {
  animation: top-image-slide-flow-ubai 140s infinite linear; /* 10 * 14*/
}

@keyframes top-image-slide-flow-ubai {
  0% {transform: translateX(0);}
  100% {transform: translateX(-466%);} /* -33.3 * 14 */
}

/* 農業ページ */

.top-image-slide-agriculture {
  animation: top-image-slide-flow-agriculture 50s infinite linear; /* 10 * 5 */
}

@keyframes top-image-slide-flow-agriculture {
  0% {transform: translateX(0);}
  100% {transform: translateX(-166%);} /* -33.3 * 5 */
}

/* 奈良紅ページ */

.top-image-slide-narabeni {
  animation: top-image-slide-flow-narabeni 30s infinite linear; /* 10 * 3 */
}

@keyframes top-image-slide-flow-narabeni {
  0% {transform: translateX(0);}
  100% {transform: translateX(-100%);} /* -33.3 * 3 */
}
